import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import IconSearch from '../../../../assets/images/icon-search.svg';
import CustomButton from '../../../../components/Button/CustomButton';
import PlusIcon from '../../../../components/Icons/PlusIcon';
import {
  ActionsBar,
  FilterButton,
  SearchCategoryWrapper,
  SearchInput,
  SelectWrapper,
  TableWrapper,
  UpdateCategoryWrapper,
  Wrapper,
} from './style';
import TableContent from '../../../../components/TableContent';
import IconEditStyle from '../../../../assets/images/icon-edit-style.svg';
import IconDeleteStyle from '../../../../assets/images/icon-delete-style.svg';
import { formatDateToYYYYMMDDHHMM } from '../../../../utils/helper';
import Pagination from '../../../../components/Pagination';
import ModifyCategoryModal from './ModifyCategoryModal';
import { useMutation } from 'react-query';
import { deleteCategory, getCategoryList } from '../../../../services/categoryService';
import { ToastError, ToastSuccess } from '../../../../components/toast-message/ToastMessage';
import ModalConfirmDelete from '../../../../components/ModalConfirmDelete';
import PriorityModal from './Priority/PriorityModal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Select } from 'antd';
import { SortOption } from '../../../../constants';
import ArrowDownIcon from '../../../../components/Icons/ArrowDownIcon';
import { DEFAULT_CATEGORY_TYPE, categoryTypeOptions, propertyOption, columnsCategory, CategoryType } from './constant';
import { SUSCRIPTION_TYPE } from '../../../../config/constants';
import IconFilter from '../../../../assets/images/icon-filter.svg';
import CategoryFilter from './Filter';

interface PropsType {
  segment?: string;
  projectName?: string;
  listImageSize?: any;
}

export default function Category({ segment }: PropsType) {
  let { id } = useParams();
  const location = useLocation();
  const [params, setParams] = useState<any>({
    limit: 10,
    page: 1,
    segmentId: segment,
    orderBy: SortOption[0].value,
    orderType: 'asc',
    projectId: id,
    type: location?.state?.type || DEFAULT_CATEGORY_TYPE,
    property: '',
  });
  const [openModifyModal, setOpenModifyModal] = useState(false);
  const [categorySelected, setCategorySelected] = useState<any>(null);
  const [categories, setCategories] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openUpdatePriority, setOpenUpdatePriority] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const projectId = sessionStorage.getItem('projectId') || '';

  const mutationGetCategories = useMutation(
    () => {
      setLoading(true);
      return getCategoryList(params);
    },
    {
      onSuccess: (res) => {
        setLoading(false);
        let data = res?.data;
        if (data?.items && Array.isArray(data?.items)) {
          setCategories(
            data?.items.map((category: any) => ({
              ...category,
              count: category.styles?.length || 0,
              property: category.property || '',
            })) || []
          );
        }
        setTotal(data?.totalItems);
      },
      onError: (error: any) => {
        setLoading(false);
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    }
  );

  const mutationDeleteCategory = useMutation((id: string) => deleteCategory([id]), {
    onSuccess: (res) => {
      if (res.statusCode === 200) {
        ToastSuccess('Category has been deleted');
        if (params.page === 1) {
          mutationGetCategories.mutate();
        } else {
          setParams({ ...params, page: 1 });
        }
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });

  useEffect(() => {
    mutationGetCategories.mutate();
  }, [JSON.stringify(params)]);

  useEffect(() => {
    if (typeof segment === 'string') {
      setParams({ ...params, segmentId: segment });
    }
  }, [segment]);

  const navigateCategoryStyle = (record: any) => {
    if (params?.type === CategoryType.avatar) {
      navigate(`/projects/${projectId}/avatar/${record._id}`)
    } else {
      navigate(`/projects/${projectId}/${record._id}`)
    }
  }

  const handleEdit = (record: any) => {
    setCategorySelected(record);
    setOpenModifyModal(true);
  }

  const handleOpenDelete = (category: any) => {
    setOpenDelete(true);
    setCategorySelected(category);
  };

  const columns = columnsCategory(params, navigateCategoryStyle, handleEdit, handleOpenDelete);

  const handleCancelDelete = () => {
    setOpenDelete(false);
    setCategorySelected(null);
  };

  const handleDelete = () => {
    handleCancelDelete();
    mutationDeleteCategory.mutate(categorySelected?._id);
  };

  const handleSearch = (e: any) => {
    const value = e.target?.value;
    setParams({ ...params, search: value });
  };

  const handleOpenPriorityModal = () => {
    setOpenUpdatePriority(true);
  };

  const handleCloseModifyModal = () => {
    setOpenModifyModal(false);
    setCategorySelected(null);
  };

  const handleSort = (value: string) => {
    let newParams: any = { ...params };

    if (value === SortOption[0].value) {
      newParams = { ...newParams, orderBy: value, orderType: 'asc' };
    } else {
      newParams = { ...newParams, orderBy: value, orderType: -1 };
    }
    setParams(newParams);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleType = (value: string) => {
    setParams({ ...params, type: value });
  };
  return (
    <Wrapper>
      <ActionsBar>
        <SearchCategoryWrapper>
          <SearchInput
            allowClear
            //   value={nameSearch}
            prefix={<img src={IconSearch} alt="" />}
            placeholder="Search..."
            onChange={debounce(handleSearch, 1000)}
          />
          <FilterButton
            onClick={() => {
              setOpenFilter(true);
            }}
            icon={<img src={IconFilter} alt="icon filter" />}>
            Filter
          </FilterButton>
        </SearchCategoryWrapper>

        <UpdateCategoryWrapper>
          <SelectWrapper>
            <Select
              value={params.type}
              onChange={handleType}
              options={categoryTypeOptions}
              suffixIcon={<ArrowDownIcon />}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              value={params.orderBy}
              onChange={handleSort}
              options={SortOption}
              suffixIcon={<ArrowDownIcon />}
            />
          </SelectWrapper>
          <CustomButton type="outlined" content="Edit Priority" onClick={handleOpenPriorityModal} />
          <CustomButton
            content="Add Category"
            prefix={<PlusIcon />}
            onClick={() => {
              setOpenModifyModal(true);
            }}
          />
        </UpdateCategoryWrapper>
      </ActionsBar>

      <TableWrapper>
        <TableContent loading={loading} columns={columns} dataSource={categories} height="50vh" />
        {total > 0 && (
          <Pagination
            limit={params.limit}
            total={total}
            params={params}
            setParams={setParams}
            showOption={false}
          />
        )}
      </TableWrapper>
      {openModifyModal && (
        <ModifyCategoryModal
          open={openModifyModal}
          onClose={handleCloseModifyModal}
          type={categorySelected ? 'update' : 'add'}
          category={categorySelected}
          refreshData={() => {
            mutationGetCategories.mutate();
          }}
        />
      )}
      <ModalConfirmDelete
        open={openDelete}
        handleCancelDelete={handleCancelDelete}
        handleDelete={handleDelete}
        title={'Are you sure to delete category?'}
        tittleDelete={'Delete'}
      />
      {openUpdatePriority && (
        <PriorityModal
          open={openUpdatePriority}
          onClose={() => {
            setOpenUpdatePriority(false);
          }}
          refreshData={() => {
            mutationGetCategories.mutate();
          }}
          styleType={params.type}
          property={params.property}
        />
      )}
      <CategoryFilter
        params={params}
        open={openFilter}
        handleClose={handleCloseFilter}
        handleApply={(value: any) => {
          setParams({ ...params, ...value });
        }}
      />
    </Wrapper>
  );
}
