export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  STYLE: '/style',
  PROJECT: '/projects',
  PROJECT_DETAIL: '/projects/:id',
  ACCOUNT: '/account',
  CATEGORY_DETAIL: '/projects/:projectId/:categoryId',
  CATEGORY_DETAIL_AVATAR: '/projects/:projectId/avatar/:categoryId',
};
