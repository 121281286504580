import { Select, Tabs, TabsProps } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { ToastError } from '../../components/toast-message/ToastMessage';
import { AI_ArtiMind_IOS, ROLES, SegmentDefault, TABS } from '../../constants';
import { getAllSegmentApi, getDetailSegment } from '../../services/segmentService';
import IcBackColor from './../../assets/icons/ic_back_color.svg';
import IcDownSelect from './../../assets/icons/ic_down_select.svg';
import Category from './component/Category';
import { ListStyle } from './component/ListStyle';
import { SegmentManage } from './component/SegmentManage';
import { BtnSegment, DetailProjectWrapper, FrameHeader, Space, TabCustom } from './style';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useDispatch } from 'react-redux';
import { setListImageSize, setSegment } from '../../redux/slices/styleSlice';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { AvatarStyle } from './component/AvatarStyle';
const DetailProject = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [showSegment, setShowSegment] = useState(false);
  const [segmentOption, setSegmentOption] = useState<any>([]);
  const [segmentSelect, setSegmentSelect] = useState<any>(
    location?.state?.segment || searchParams.get('segmentId') || SegmentDefault[0].value
  );
  const [tabActive, setTabActive] = useState(location?.state?.tab || TABS.TAB1);
  const [imageSize, setImageSize] = useState({});
  const { id } = useParams();
  const projectName = sessionStorage.getItem('projectName');
  const role = useSelector((state: RootState) => state.auth.userInfo?.role);

  const items: TabsProps['items'] = [
    {
      key: TABS.TAB1,
      label: 'LIST STYLE',
      children: (
        <ListStyle segmentSelect={segmentSelect} tabActive={tabActive} listImageSize={imageSize} />
      ),
    },
    {
      key: TABS.TAB2,
      label: 'CATEGORY LIST',
      children: <Category segment={segmentSelect} listImageSize={imageSize} />,
    },
    {
      key: TABS.TAB3,
      label: 'Avatar style',
      children: <AvatarStyle segmentSelect={segmentSelect} tabActive={tabActive} listImageSize={imageSize} />,
    }
  ];

  useEffect(() => {
    muatationListSegment.mutate();
    dispatch(setSegment(SegmentDefault[0].value));
  }, []);
  useEffect(() => {
    muatationListSegment.mutate();
  }, [tabActive, showSegment]);
  const onChange = (key: string) => {
    setTabActive(key);
  };

  const handleChange = (value: string) => {
    if (!value) {
      searchParams.delete('segmentId');
    }
    setSegmentSelect(value);
    mutationDetailSegment.mutate(value);
    dispatch(setSegment(value));
  };

  const handleReload = () => {
    muatationListSegment.mutate();
  };

  const muatationListSegment = useMutation(() => getAllSegmentApi(id), {
    onSuccess: (res) => {
      if (res?.data?.length) {
        const data = res?.data.map((item: any) => {
          return {
            label: item?.name.length > 22 ? item?.name.slice(0, 22) + '...' : item?.name,
            value: item?._id,
          };
        });
        setSegmentOption(SegmentDefault.concat(data));
      } else {
        setSegmentOption(SegmentDefault);
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });
  const mutationDetailSegment = useMutation((id: string) => getDetailSegment(id), {
    onSuccess: (res) => {
      if (res && res.statusCode === 200) {
        const data = res?.data;
        const listImageSize = {
          art: data.aiArtImageSize,
          ai_fashion: data.aiFashionImageSize,
          avatar: data.aiAvatarImageSize,
        };
        setImageSize(listImageSize);
        dispatch(setListImageSize(listImageSize));
      }
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
    },
  });
  return (
    <DetailProjectWrapper>
      <div className="content">
        <FrameHeader>
          <div className="title">{projectName}</div>
          <Space />
          <BtnSegment show={showSegment} onClick={() => setShowSegment(false)}>
            <img src={IcBackColor} />
            Back to style
          </BtnSegment>
          {role === ROLES.ADMIN && (
            <BtnSegment show={!showSegment} onClick={() => setShowSegment(true)}>
              Manage segment
            </BtnSegment>
          )}
          <Select
            style={{ display: showSegment ? 'none' : 'flex' }}
            value={segmentSelect}
            onChange={handleChange}
            options={segmentOption}
            suffixIcon={<img src={IcDownSelect} />}
          />
        </FrameHeader>
        {!showSegment ? (
          <TabCustom activeKey={tabActive} items={items} onChange={onChange} />
        ) : (
          <SegmentManage handleReload={handleReload} />
        )}
      </div>
    </DetailProjectWrapper>
  );
};

export default DetailProject;
