import { baseModels, FieldConfigStyle, KEY_CONFIG, StyleType } from '../../constants';
import { Avatar, Tag } from 'antd';
import { checkIsImage } from '../../utils/helper';
import IconEditStyle from '../../assets/images/icon-edit-style.svg';
import IconDeleteStyle from '../../assets/images/icon-delete-style.svg';
import React from 'react';

export const columnAvatar = (params: any, handleOpenEdit: any, handleOpenDelete: any) => {
  return [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (_: any, record: any, index: number) => params.limit * (params.page - 1) + index + 1,
      width: 70,
    },
    {
      title: 'STYLE NAME ON APP',
      dataIndex: 'name',
      key: 'name',
      width: 170,
    },
    {
      title: 'POSITIVE PROMPT',
      dataIndex: 'config',
      key: 'positivePrompt',
      width: 180,
      render: (value: any) => {
        return <div>{value?.positivePrompt || ''}</div>;
      },
    },
    {
      title: 'CONFIG',
      dataIndex: 'config',
      key: 'config',
      className: 'config-column',
      width: 320,
      render: (value: any, record: any) => {
        return (
          <div>
            <ul>
              {Object.keys(value)?.map((key: string) => {

                return (
                  <>
                    <li>{`${key} : ${
                      value[key] !== undefined ? value[key] : ''
                    }`}</li>
                  </>
                );
              })}
            </ul>
          </div>
        );
      },
    },
    {
      title: 'THUMBNAIL ON APP',
      dataIndex: 'thumbnailApp',
      key: 'thumbnailApp',
      className: 'thumbnail-column',
      width: '240px',
      render: (value: any) => (<Avatar shape="square" size={204} src={value[0]?.thumbnailUrl || ''}></Avatar>),
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 100,
      className: 'action-column',
      fixed: 'right',
      render: (id: string, record: any) => (
        <div className="list-action">
          <img src={IconEditStyle} alt="" onClick={() => handleOpenEdit(record)} />
          <img src={IconDeleteStyle} alt="" onClick={() => handleOpenDelete(id)} />
        </div>
      ),
    },
  ]
}