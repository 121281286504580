import httpService from './../config/http.service';
import { PROJECT_DEFAULT } from '../constants';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getListStyleSelectors = async (type?: string) => {
  const res = await httpService(authUrl).get(`/style-selectors?type=${type}`);
  return res?.data?.data;
};

export const getListStyle = async (params?: any) => {
  const query = '?' + new URLSearchParams(params).toString();
  const res = await httpService(authUrl).get(`/v3/styles` + query);
  return res?.data.data;
};

export const getListStyleOfCategory = async (params?: any) => {
  const res = await httpService(authUrl).get(`/v3/styles`, {
    params,
  });
  return res?.data.data;
};

export const getListStyleOfCategoryAvatar = async (id: any, params?: any) => {
  const res = await httpService(authUrl).get(`/style-avatar/by-category/${id}`, {
    params,
  });
  return res?.data.data;
};

export const getAllStyle = async (params?: any) => {
  const res = await httpService(authUrl).get(`/v3/styles/all`, {
    params,
  });
  return res?.data.data;
};

export const deleteStyle = async (id: string) => {
  const res = await httpService(authUrl).delete(`/v3/styles/${id}`);
  return res?.data;
};

export const createStyle = async (payload: any) => {
  const res = await httpService(authUrl).post(`/v3/styles`, payload);
  return res?.data;
};
export const createStyleCustom = async (payload: any) => {
  const res = await httpService(authUrl).post(`/v3/styles?action=custom`, payload);
  return res?.data;
};
export const createStyleInhouse = async (payload: any) => {
  const res = await httpService(authUrl).post(`/v3/styles/inhouse`, payload);
  return res?.data;
};
export const updateStyle = async (payload: any, id: number) => {
  const res = await httpService(authUrl).put(`/v3/styles/${id}`, payload);
  return res?.data;
};
export const getStyleProject = async (params: any) => {
  const query = '?' + new URLSearchParams(params).toString();
  const res = await httpService(authUrl).get(`/v3/styles` + query);
  return res?.data;
};
export const getStyleInhouse = async (params: any) => {
  const res = await httpService(authUrl).get(
    `/v3/styles?project=${PROJECT_DEFAULT}&orderBy=priority&orderType=asc`,
    { params }
  );
  return res?.data;
};

export const arrangeCategoryStyles = async (categoryId: string, styles: any[]) => {
  const res = await httpService(authUrl).put(`/category/${categoryId}/styles-priority`, { styles });
  return res?.data;
};

export const arrangeCategoryStylesAvatar = async (data: any) => {
  const res = await httpService(authUrl).put(`/style-avatar/update-priority/`, data);
  return res?.data;
};
