import httpService from './../config/http.service';
import { PROJECT_DEFAULT } from '../constants';
const authUrl = process.env.REACT_APP_BASE_URL;

export const getAvatarStyle = async (params: any) => {
  const query = '?' + new URLSearchParams(params).toString();
  const res = await httpService(authUrl).get(`/style-avatar` + query);
  return res?.data;
};

export const createAvatarStyle = async (payload: any) => {
  const res = await httpService(authUrl).post(`/style-avatar`, payload);
  return res?.data;
}

export const updateAvatarStyle = async (payload: any, id: number) => {
  const res = await httpService(authUrl).put(`/style-avatar/${id}`, payload);
  return res?.data;
};

export const deleteAvatarStyle = async (id: string) => {
  const res = await httpService(authUrl).delete(`/style-avatar/${id}`);
  return res?.data;
};