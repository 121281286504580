import { Button, Form, Input, Select, message, Tag, Checkbox, InputNumber } from 'antd';
import { useForm, useWatch } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { UploadChangeParam } from 'antd/es/upload';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { fetchPresignUrl, uploadImageApi } from '../../services/AIStyleService';
import { FrameLabel, FrameOption, InputWrapper, ModalCreate } from './style';
import 'antd-tag-input/dist/style.css';
import {
  createStyleCustom,
  getStyleInhouse,
  updateStyle,
} from '../../services/listStyleService';
import { ToastError, ToastSuccess } from '../toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';
import {
  STYLE_MODE,
  KEY_NUMBER,
  KEY_BOOLEAN,
} from '../../constants';
import { getThumbnailType } from '../../services/listProjectService';
import IcDownBlack from '../../assets/icons/ic_down_back.svg';
import { getCategoryList } from '../../services/categoryService';
import { useParams } from 'react-router-dom';
import { convertFileUpload, convertStyleTyleToCategoryType } from '../../utils/helper';
import { ThumbnailOption } from '../ModalCustomStyleProject/component/ThumbnailApp/interface';
import { ThumbnailApp } from './component/ThumbnailApp';
import { OptionBoolean } from './constants';
import { createAvatarStyle, updateAvatarStyle } from '../../services/avatarStyleService';

const ModalAvatarStyle = (props: any) => {
  const {
    styleEdit,
    segmentSelect,
    setStyleEdit,
    open,
    setOpen,
    params,
    setParams,
    setNameSearch,
    name,
    reloadAllStyle,
  } = props;
  const projectName = sessionStorage.getItem('projectName' || '');
  const { id } = useParams();

  const [form] = useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [categoryOption, setCategoryOption] = useState([]);

  const { data: categoryList } = useQuery(
    ['get-category'],
    () => {
      let categoryTypes = convertStyleTyleToCategoryType('avatar');
      return getCategoryList({
        segmentId: segmentSelect,
        projectId: id,
        type: categoryTypes,
        page: 1,
        limit: 1000,
      });
    },
    {
      onSuccess: (res: any) => {
        if (res?.data?.items) {
          const option = res?.data?.items?.map((item: any) => ({
            label: item?.name,
            value: item?._id,
          }));
          setCategoryOption(option);
        }
      },
      onError: (error: any) => {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      },
    }
  );

  const mutationCreateStyle = useMutation((body: any) => createAvatarStyle(body), {
    onSuccess: (data) => {
      handleCancel();
      setParams({ ...params, page: 1, name: '', styles: '' });
      setNameSearch('');
      // queryClient.invalidateQueries({
      //   queryKey: ['detail-project'],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ['list-all-style-in-project'],
      // });
      reloadAllStyle();
      ToastSuccess('Create style successfully');
      setLoading(false);
    },
    onError: (error: any) => {
      // queryClient.invalidateQueries({
      //   queryKey: ['detail-project'],
      // });
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      handleCancel();
    },
  });

  const mutationUpdateStyle = useMutation((body: any) => updateAvatarStyle(body, styleEdit._id), {
    onSuccess: (data) => {
      handleCancel();
      reloadAllStyle();
      // queryClient.invalidateQueries({
      //   queryKey: ['list-all-style-in-project'],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ['detail-project'],
      // });
      // queryClient.invalidateQueries({
      //   queryKey: ['get-list-style-selectors'],
      // });
      ToastSuccess(toastMessage.success);
      setLoading(false);
    },
    onError: (error: any) => {
      ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      handleCancel();
    },
  });

  const onFinish = async (values: any) => {
    try {
      const thumbnailApp = [];
      let thumbnail = '';
      setLoading(true);
      if (values.thumbnailApp?.length) {
        for (const item of values.thumbnailApp) {
          if (!item?.thumbnailUrl?.name) {
            thumbnailApp.push(item);
          } else {
            const presign = await fetchPresignUrl(convertFileUpload(item?.thumbnailUrl?.name));
            const upload = await uploadImageApi(presign.thumbnailPresign, item?.thumbnailUrl);
            if (upload?.status !== 200) break;
            thumbnailApp.push({
              thumbnailUrl: presign.filepath,
              thumbnailSize: item?.thumbnailSize,
            });
          }
        }
      }
      if (thumbnailApp?.length < values.thumbnailApp?.length) {
        ToastError('Presign Link Error, Try Again!');
        return;
      }
      let config: any = {};
      Object.keys(values.config).forEach((key) => {
        let value = values.config[key];
        if (Number(values.config[key]) && KEY_NUMBER.includes(key)) {
          value = Number(values.config[key]);
        } else if (KEY_BOOLEAN.includes(key)) {
          value = !!value;
        }
        config[key] = value;
        config['mode'] = 'Transform';
      });
      let body = {
        ...values,
        key: thumbnail,
        config,
        project: projectName,
        thumbnailApp: thumbnailApp,
      };
      if (segmentSelect) body = { ...body, segmentId: segmentSelect };
      const params = {
        ...body,
        project: projectName,
      };
      delete params.segmentId;
      if (!styleEdit) {
        mutationCreateStyle.mutate(body);
      } else {
        mutationUpdateStyle.mutate(params);
      }
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: 'error',
        content: 'Internal Server',
      });
      return;
    }
  };

  const handleCancel = () => {
    form.resetFields();
    setOpen(false);
    setStyleEdit(null);
    setLoading(false);
  };

  const handleCreateStyle = () => {
    form.submit();
  };

  const active = useMemo(() => {
    return styleEdit?.mode !== STYLE_MODE.DEFAULT;
  }, []);
  const nonActiveCustom = useMemo(() => {
    return styleEdit?.mode !== STYLE_MODE.CUSTOM;
  }, []);

  const categoriesConvert = useMemo(() => {
    return styleEdit?.categories?.map((item: any) => item?._id || item);
  }, [styleEdit?.categories]);

  return (
    <>
      <ModalCreate
        width={841}
        closable={false}
        footer={null}
        centered
        open={open}
        onCancel={handleCancel}>
        {contextHolder}
        <Form
          requiredMark={true}
          colon={false}
          form={form}
          name="createStyle"
          // layout="horizontal"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          initialValues={{
            name: styleEdit?.name || '',
            config: styleEdit?.config || {},
            thumbnailApp: styleEdit?.thumbnailApp || [],
            categories: categoriesConvert || [],
            key: styleEdit?.key || '',
          }}
          onFinish={onFinish}
          autoComplete="off">
          <Form.Item
            label="AI style name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name of style!',
                type: 'string',
              },
              {
                max: 30,
                message: 'The name of the style cannot be longer than 30 characters!',
              },
              {
                validator: (_, value) => {
                  if (value && value.trim().length === 0) {
                    return Promise.reject('The name of the style cannot contain only spaces!');
                  }
                  return Promise.resolve();
                },
              },
              {
                validator: (_, value) => {
                  if (!styleEdit) {
                    if (name?.includes(value)) {
                      return Promise.reject('Style name must be unique!');
                    }
                  }
                  if (styleEdit) {
                    if (value !== styleEdit.name) {
                      if (name?.includes(value)) {
                        return Promise.reject('Style name must be unique!');
                      }
                    }
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <Input
              name="name"
              size="large"
              className="name-input"
              placeholder="Anime"
              maxLength={30}
              onKeyDown={(e) => {
                const input = e.target as HTMLInputElement;
                const value = input.value;
                if (value.length >= 30) {
                  // Ngăn chặn việc nhập ký tự nếu đã đạt đến giới hạn 30 ký tự
                  if (e.key !== 'Backspace' && e.key !== 'Delete') {
                    e.preventDefault();
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Positive prompt"
            name={['config', 'positivePrompt']}
            rules={[
              {
                required: true,
                message: 'Please input positive prompt!',
                type: 'string',
              },
              () => ({
                validator(_, value?: any) {
                  if (value) {
                    if (typeof value !== 'string') {
                      return Promise.reject('Positive prompt must be string');
                    } else if (value.length > 1000) {
                      return Promise.reject('Max length of Positive prompt is 1000');
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}>
            <InputWrapper
              name="config.positivePrompt"
              size="large"
              maxLength={1000}
              onKeyDown={(e) => {
                const input = e.target as HTMLInputElement;
                const value = input.value;
                if (value.length >= 1000) {
                  // Ngăn chặn việc nhập ký tự nếu đã đạt đến giới hạn 1000 ký tự
                  if (e.key !== 'Backspace' && e.key !== 'Delete') {
                    e.preventDefault();
                  }
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label="Fix width"
            name={['config', 'fixWidth']}
            rules={[]}>
            <InputNumber
              min={0} max={1280} defaultValue={0}
              name="config.fixWidth"
              size="large"
            />
          </Form.Item>
          <Form.Item
            label="Fix height"
            name={['config', 'fixHeight']}
            rules={[]}>
            <InputNumber
              min={0} max={1280} defaultValue={0}
              name="config.fixHeight"
              size="large"
            />
          </Form.Item>
          <Form.Item label="Fix width and height" name={['config', 'fixWidthAndHeight']}>
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              options={OptionBoolean}
              size="large"
              placeholder="Select..."
            />
          </Form.Item>
          <Form.Item label="Use control net" name={['config', 'useControlnet']}>
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              options={OptionBoolean}
              size="large"
              placeholder="Select..."
            />
          </Form.Item>
          <Form.Item label="Apply publid" name={['config', 'applyPulid']}>
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              options={OptionBoolean}
              size="large"
              placeholder="Select..."
            />
          </Form.Item>
          <Form.Item label="Fast mode" name={['config', 'fastMode']}>
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              options={OptionBoolean}
              size="large"
              placeholder="Select..."
            />
          </Form.Item>
          <Form.Item label="Category" name={'categories'}>
            <Select
              suffixIcon={<img src={IcDownBlack} />}
              size="large"
              placeholder="Select..."
              mode="multiple"
              allowClear
              filterOption={(inputValue: any, option: any) =>
                option?.children?.props.children?.toLowerCase().includes(inputValue?.toLowerCase())
              }>
              {categoryOption.map((option: any) => (
                <FrameOption value={option?.value}>
                  <FrameLabel>{option?.label}</FrameLabel>
                </FrameOption>
              ))}
            </Select>
          </Form.Item>
          <ThumbnailApp form={form} />
          {/*<Thumbnail form={form} />*/}
          <div className="group-btn">
            <Button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="create-btn" onClick={handleCreateStyle} loading={loading}>
              {styleEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </Form>
      </ModalCreate>
    </>
  );
};

export default ModalAvatarStyle;
