import React, { useEffect, useMemo, useState } from 'react';
import {
  FrameHeaderUpload,
  FrameImg,
  FrameListThumbnailApp,
  FrameThumbnail,
  ImgIcClose,
  ImgIcUpload,
  TxtTitleThumb,
} from './styles';
import IcUploadBlack from '../../../../assets/icons/ic_upload_black.svg';
import IcCloseBlack from '../../../../assets/icons/ic_close_back.svg';
import { IProps, IThumbnail, ThumbnailOption } from './interface';
import { Form, Select } from 'antd';
import { useMutation } from 'react-query';
import { createThumbnailType, getThumbnailType } from '../../../../services/listProjectService';
import { ToastError } from '../../../toast-message/ToastMessage';
import { checkIsImage, FileFormat, ImageType } from '../../../../utils/helper';
import { useParams } from 'react-router-dom';
import { useWatch } from 'antd/lib/form/Form';

export const ThumbnailApp = (props: IProps) => {
  const { form } = props;

  const handleButtonClick = () => {
    document.getElementById('fileInput')?.click();
  };

  const handleFileChange = (e: any) => {
    if (!e.target.files?.length) return;
    for (const file of e.target.files) {
      if (!file) return;
      const maxSizeInBytes = 10 * 1024 * 1024;
      if (file && file.size > maxSizeInBytes) {
        ToastError('Max file size is 10mb');
        e.target.value = null;
        return;
      }
      const arr = file?.name.split('.');
      if (!FileFormat.includes(arr[arr.length - 1].toLowerCase())) {
        ToastError('You can upload image file');
        e.target.value = null;
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener('load', (event: any) => {
        const _loadedImageUrl = event.target.result;
        const image = document.createElement('img');
        image.src = _loadedImageUrl;
        image.addEventListener('load', () => {
          const { width, height } = image;
          form.setFieldsValue({
            thumbnailApp: [
              ...form.getFieldValue('thumbnailApp'),
              {
                thumbnailUrl: file,
                thumbnailSize: `${width}x${height}`,
                isFile: true,
              },
            ],
          });
          e.target.value = null;
        });
      });
    }
  };

  return (
    <FrameListThumbnailApp>
      <FrameHeaderUpload>
        <TxtTitleThumb>{'Thumbnail app'}</TxtTitleThumb>
        <ImgIcUpload src={IcUploadBlack} onClick={handleButtonClick} />
        <input
          type="file"
          id="fileInput"
          accept="image/png, image/gif, image/jpeg"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          multiple
        />
      </FrameHeaderUpload>
      <Form.List name="thumbnailApp">
        {(fields, { add, remove }) => (
          <div style={{ marginTop: fields?.length ? '37px' : '0' }}>
            {fields.map(({ key, name, fieldKey, ...restField }: any, index: number) => {
              const thumbnail = form.getFieldValue(['thumbnailApp', index]);
              const isFile = typeof thumbnail?.thumbnailUrl !== 'string';
              return (
                <FrameThumbnail>
                  <FrameImg
                    alt="thumbnail"
                    src={isFile ? URL.createObjectURL(thumbnail.thumbnailUrl) : thumbnail.thumbnailUrl}
                  />
                  <ImgIcClose src={IcCloseBlack} onClick={() => remove(index)} />
                </FrameThumbnail>
              );
            })}
            <Form.Item></Form.Item>
          </div>
        )}
      </Form.List>
    </FrameListThumbnailApp>
  );
};
