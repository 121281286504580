import React, { useEffect, useState } from 'react';
import { IProps } from './interface';
import { Avatar, Button, Input, Select, Tag } from 'antd';
import IconSearch from '../../../../assets/images/icon-search.svg';
import TableContent from '../../../../components/TableContent';
import Pagination from '../../../../components/Pagination';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ModalEditStyleProject from '../../../../components/ModalEditStyleProject';
import ModalConfirmDelete from '../../../../components/ModalConfirmDelete';
import ModalEditPriority from '../../../../components/ModalEditPriority';
import IconEditStyle from '../../../../assets/images/icon-edit-style.svg';
import IconDeleteStyle from '../../../../assets/images/icon-delete-style.svg';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ToastError, ToastSuccess } from '../../../../components/toast-message/ToastMessage';
import { toastMessage } from '../../../../config/constants';
import { FrameListTag, FrameTag, TxtTag, Wrapper } from './style';
import { checkIsImage, formatDateToYYYYMMDDHHMM } from '../../../../utils/helper';
import {
  SortOption,
  TABS,
  styleTypes,
  STYLE_MODE_FILTER_OPTION,
} from '../../../../constants';
import IcDownSelect from '../../../../assets/icons/ic_down_select.svg';
import { DrawerWrapper } from '../../../ListStyle/style';
import IconCloseFilter from '../../../../assets/images/icon-close-filter.svg';
import IcDownBlack from '../../../../assets/icons/ic_down_back.svg';
import IconFilter from '../../../../assets/images/icon-filter.svg';
import ModalAvatarStyle from '../../../../components/ModalAvatarStyle';
import { deleteAvatarStyle, getAvatarStyle } from '../../../../services/avatarStyleService';

export const AvatarStyle = (props: IProps) => {
  const { segmentSelect, tabActive, listImageSize } = props;
  const columns = [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (_: any, record: any, index: number) => params.limit * (params.page - 1) + index + 1,
      width: 70,
    },
    {
      title: 'STYLE NAME ON APP',
      dataIndex: 'name',
      key: 'name',
      width: 170,
    },
    {
      title: 'POSITIVE PROMPT',
      dataIndex: 'config',
      key: 'positivePrompt',
      width: 180,
      render: (value: any) => {
        return <div>{value?.positivePrompt || ''}</div>;
      },
    },
    {
      title: 'CONFIG',
      dataIndex: 'config',
      key: 'config',
      className: 'config-column',
      width: 320,
      render: (value: any, record: any) => {
        delete value._id;
        return (
          <div>
            <ul>
              {Object.keys(value)?.map((key: string) => {

                  return (
                    <>
                      <li>{`${key} : ${
                        value[key] !== undefined ? value[key] : ''
                      }`}</li>
                    </>
                  );
                })}
            </ul>
          </div>
        );
      },
    },
    {
      title: 'CATEGORY',
      dataIndex: 'categories',
      key: 'categories',
      className: 'thumbnail-column',
      width: '240px',
      render: (value: any) => {
        return (
          <FrameListTag>
            {value?.map((e: any, index: number) => (
              <FrameTag key={index}>
                <TxtTag>{e?.name?.length > 20 ? e?.name.slice(0, 20) + '...' : e?.name}</TxtTag>
              </FrameTag>
            ))}
          </FrameListTag>
        );
      },
    },
    {
      title: 'THUMBNAIL ON APP',
      dataIndex: 'thumbnailApp',
      key: 'thumbnailApp',
      className: 'thumbnail-column',
      width: '240px',
      render: (value: any) => {
        const isImage = checkIsImage(value?.length ? value[0]?.thumbnailUrl : '');
        console.log('isImage', isImage, value);
        
        if (isImage) return <Avatar shape="square" size={204} src={value[0]?.thumbnailUrl || ''}></Avatar>;
        return (
          <video width="204px" height="204px" key={value[0]?.thumbnailUrl || ''} controls>
            <source src={value[0]?.thumbnailUrl || ''} />
          </video>
        );
      },
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'createdAt-column',
      width: '180px',
      render: (value: string) => {
        const formattedTime = formatDateToYYYYMMDDHHMM(value);
        return <div>{formattedTime || ''}</div>;
      },
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 100,
      className: 'action-column',
      fixed: 'right',
      render: (id: string, record: any) => (
        <div className="list-action">
          <img src={IconEditStyle} alt="" onClick={() => handleOpenEdit(record)} />
          <img src={IconDeleteStyle} alt="" onClick={() => handleOpenDelete(id)} />
        </div>
      ),
    },
  ];

  const [searchParams, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const projectName = sessionStorage.getItem('projectName');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [styleEdit, setStyleEdit] = useState<any>();

  const [params, setParams] = useState<any>({
    limit: 15,
    page: 1,
    project: projectName,
    orderBy: SortOption[0].value,
    segmentId: searchParams.get('segmentId') || '',
  });
  const [nameSearch, setNameSearch] = useState('');

  const [listAddStyle, setListAddStyle] = useState<any>([]);

  const [idStyleDelete, setIdStyleDelete] = useState('');

  let [debounce, setDebounce] = useState<any>();

  const [openFilter, setOpenFilter] = useState(false);
  const [styleFilter, setStyleFilter] = useState('');
  const [styleModeFilter, setStyleModeFilter] = useState('');
  useEffect(() => {
    let param: any = {
      page: 1,
      limit: 15,
      search: '',
      name: '',
      project: projectName,
      orderBy: SortOption[0].value,
      segmentId: '',
      // orderType: 'desc'
    };
    if (segmentSelect) param = { ...param, segmentId: segmentSelect };
    const page = searchParams.get('page');
    const limit = searchParams.get('limit');
    const search = searchParams.get('search');
    const segment = searchParams.get('segmentId') || '';
    if (page) {
      param.page = Number(page);
    }
    if (limit) {
      param.limit = Number(limit);
    }
    if (search) {
      param.search = search;
    }
    if (segment) {
      param.segmentId = segment;
    }
    const updatedParams = { ...param };
    setParams(updatedParams);
    setSearchParams(updatedParams);
  }, []);

  useEffect(() => {
    let payload = { ...params };
    if (segmentSelect) {
      payload = {
        ...params,
        segmentId: segmentSelect,
      };
    }
    if (!segmentSelect) delete payload.segmentId;
    setParams(payload);
  }, [segmentSelect]);

  useEffect(() => {
    if (tabActive === TABS.TAB3) {
      queryClient.invalidateQueries({
        queryKey: ['avatar-style', params],
      });
    }
  }, [segmentSelect, tabActive]);

  useEffect(() => {
    const searchParams: any = {};
    for (let key in params) {
      const value = params[key];
      if (value) {
        searchParams[key] = value;
      }
    }
    setSearchParams(searchParams);
  }, [params]);

  const { isLoading, data: detailProject } = useQuery(
    ['avatar-style', params],
    () => getAvatarStyle(params),
    {
      onSuccess: (res: any) => {
        const data = res?.data?.items || [];
        setListAllStyleInProject(res);
        if (data?.length === 0 && params.page > 1) {
          setParams({ ...params, page: params.page - 1 });
        }
      },
    }
  );

  const name: string[] = detailProject?.data?.items.map((item: any) => {
    return item.name;
  });

  const [listAllStyleInProject, setListAllStyleInProject] = useState({
    data: { items: [] },
  });

  const muatationDeleteStyle = useMutation((id: string) => deleteAvatarStyle(id), {
    onSuccess: (data) => {
      handleCancelDelete();
      queryClient.invalidateQueries({
        queryKey: ['avatar-style'],
      });
      ToastSuccess('Delete successfully');
    },
    onError: () => {
      ToastError(toastMessage.error);
    },
  });

  const [openCustom, setOpenCustom] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [openEditPriority, setOpenEditPriority] = useState(false);

  const handleCustomStyle = () => {
    setOpenCustom(true);
  };
  const handleOpenDelete = (id: string) => {
    setOpenDelete(true);
    setIdStyleDelete(id);
  };

  const handleCancelDelete = () => {
    setOpenDelete(false);
    setIdStyleDelete('');
  };
  const handleDelete = () => {
    setOpenDelete(false);
    muatationDeleteStyle.mutate(idStyleDelete);
    if ((detailProject.data.totalItems - 1) % detailProject.data.limit === 0)
      setParams({ ...params, limit: detailProject.data.limit, page: detailProject.data.page - 1 });
  };
  const handleOpenEdit = (style: any) => {
    setStyleEdit(style);
    setOpenCustom(true);
  };
  window.addEventListener('popstate', () => {
    navigate('/projects');
  });
  const handleOpenEditPriority = () => {
    setOpenEditPriority(true);
  };

  const handleChangeSearchName = (e: any) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        setParams({ ...params, search: e.target.value, page: 1 });
      }, 500)
    );
    setNameSearch(e.target.value);

    const dataSearch = detailProject?.data?.items.filter((item: any) => {
      return item.name === nameSearch;
    });
    return dataSearch;
  };

  const handleChange = (value: string) => {
    setParams({
      ...params,
      orderBy: value,
      orderType: value === SortOption[0].value ? 'asc' : 'desc',
    });
  };

  const reloadAllStyle = () => {
    queryClient.invalidateQueries({
      queryKey: ['avatar-style'],
    });
  };

  const showDrawerFilter = () => {
    setOpenFilter(true);
    // setLoading(true);
  };

  const onCloseDrawerFilter = () => {
    setOpenFilter(false);
  };

  const hanldeResetDrawerFilter = () => {
    setStyleFilter('');
    setStyleModeFilter('');
    setParams({
      ...params,
      styleType: '',
      styleMode: '',
    });
  };

  const handleApplyDrawerFilter = () => {
    if (styleFilter || styleModeFilter) {
      setParams({ ...params, styleType: styleFilter, styleMode: styleModeFilter, page: 1 });
      setOpenFilter(false);
    } else {
      setOpenFilter(false);
    }
  };

  const handleChangeFilterStyle = (style: string) => {
    setStyleFilter(style);
  };
  const handleChangeFilterStyleMode = (mode: string) => {
    setStyleModeFilter(mode);
  };

  return (
    <Wrapper>
      <div className="list-style">
        <div className="box-search">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Input
              allowClear
              value={nameSearch}
              prefix={<img src={IconSearch} alt="" />}
              className="input-search"
              placeholder="Search style name on app"
              onChange={handleChangeSearchName}
            />
            <div className="filter" onClick={showDrawerFilter}>
              <img src={IconFilter} alt="" />
              <span>Filter</span>
            </div>
          </div>

          <div className="group-btn">
            <Select
              suffixIcon={<img src={IcDownSelect} />}
              value={params.orderBy}
              onChange={handleChange}
              options={SortOption}
            />
            {/*<Button className="edit-priority" onClick={handleOpenEditPriority}>
              Edit priority
            </Button>*/}
            <div className="add-ai" onClick={handleCustomStyle}>
              <div className="plus">+</div>
              <div>Custom AI style</div>
            </div>
          </div>
        </div>
        <TableContent
          loading={isLoading || segmentSelect === null}
          columns={columns}
          dataSource={listAllStyleInProject?.data.items}
          height="45vh"
        />
        <Pagination
          limit={detailProject?.data.limit || 0}
          total={detailProject?.data.totalItems || 0}
          params={params}
          setParams={setParams}
        />
      </div>
      <ModalEditStyleProject
        open={openEdit}
        setOpenEdit={setOpenEdit}
        styleEdit={styleEdit}
        setStyleEdit={setStyleEdit}
        projectId={id}
      />
      <ModalConfirmDelete
        open={openDelete}
        handleCancelDelete={handleCancelDelete}
        handleDelete={handleDelete}
        title={'Are you sure to delete style?'}
        tittleDelete={'Delete'}
      />
      <ModalEditPriority
        open={openEditPriority}
        setOpen={setOpenEditPriority}
        listStyle={listAllStyleInProject?.data.items}
        projectId={id}
        params={params}
        setParams={setParams}
        setNameSearch={setNameSearch}
        detail={detailProject}
        reloadAllStyle={reloadAllStyle}
      />
      {openCustom && (
        <ModalAvatarStyle
          projectName={projectName}
          listAddStyle={listAddStyle}
          detailProject={detailProject}
          open={openCustom}
          setOpen={setOpenCustom}
          params={params}
          setParams={setParams}
          setNameSearch={setNameSearch}
          styleEdit={styleEdit}
          name={name}
          setStyleEdit={setStyleEdit}
          // listStyleInhouse={listStyleInhouse}
          segmentSelect={segmentSelect}
          reloadAllStyle={reloadAllStyle}
          listImageSize={listImageSize}
          isAvatar={true}
        />
      )}
      <DrawerWrapper
        placement="right"
        open={openFilter}
        closable={false}
        width={480}
        onClose={onCloseDrawerFilter}>
        <div>
          <div className="title-filter">
            <span>Advanced Filter</span>
            <img src={IconCloseFilter} alt="" onClick={onCloseDrawerFilter} />
          </div>
          <div className="style">Style type</div>
          <Select
            suffixIcon={<img src={IcDownBlack} />}
            style={{ width: '100%' }}
            options={styleTypes}
            size="large"
            placeholder="none"
            disabled={!!styleEdit}
            value={styleFilter}
            onChange={handleChangeFilterStyle}
          />
          <div className="style">Style Mode</div>
          <Select
            suffixIcon={<img src={IcDownBlack} />}
            style={{ width: '100%' }}
            options={STYLE_MODE_FILTER_OPTION}
            size="large"
            placeholder="none"
            disabled={!!styleEdit}
            value={styleModeFilter}
            onChange={handleChangeFilterStyleMode}
          />
        </div>
        <div className="footer">
          <span className="reset" onClick={hanldeResetDrawerFilter}>
            Reset
          </span>
          <div>
            <Button className="btn-cancel" onClick={onCloseDrawerFilter}>
              Cancel
            </Button>
            <Button className="btn-apply" onClick={handleApplyDrawerFilter}>
              Apply
            </Button>
          </div>
        </div>
      </DrawerWrapper>
    </Wrapper>
  );
};
