import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ToastError, ToastSuccess } from '../../../../components/toast-message/ToastMessage';
import { createCategory, updateCategory } from '../../../../services/categoryService';
import {
  CategoryProperty,
  CategoryType,
  SubOptions,
  SubscriptionType,
  categoryNameMaxLength,
  categoryTypeOptions,
  propertyOption,
} from './constant';
import { useForm } from 'antd/es/form/Form';
import { ThumbnailApp } from '../../../../components/ModalAvatarStyle/component/ThumbnailApp';
import { fetchPresignUrl, uploadImageApi } from '../../../../services/AIStyleService';
import { convertFileUpload } from '../../../../utils/helper';
import { InputCustom } from '../../../../components/InputCustom';
import TextArea from 'antd/es/input/TextArea';

export const ModalWrapper = styled(Modal)`
  top: 290px;
  .ant-modal-title {
    color: var(--scale-gray-scale-high-emp, #131317);
    font-family: Noto Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }
  .ant-modal-content {
    padding: 24px 32px;
  }
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .ant-btn-default {
      border-radius: 8px;
      background: var(--scale-light-scale-background, #f7f7f7);
      border: none;
      display: flex;
      width: 140px;
      height: max-content;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      span {
        color: var(--scale-gray-scale-high-emp, #131317);

        /* Body/ Semi */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
      &:hover {
        span {
          color: var(--scale-gray-scale-high-emp, #131317);
        }
      }
    }
    .ant-btn-primary {
      border-radius: 8px;
      background: var(--primary-primary, #f37f13);
      border: none;
      display: flex;
      width: 140px;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      height: max-content;
      span {
        color: var(--scale-light-scale-white, #fff);

        /* Body/ Semi */
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 137.5% */
      }
      &:hover {
        background: var(--primary-primary, #f37f1390);
      }
    }
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 36px 0px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 10px;
`;

const Label = styled.p`
  margin-bottom: 0px;
  color: var(--scale-gray-scale-med-emp, #8c8c92);

  /* Caption 1/ Medium */
  font-family: Noto Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  span {
    color: var(--Alert, #f31313);

    /* Caption 1/ Medium */
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const ErrorText = styled.p`
  margin-bottom: 0px;
  color: #f31313;
  font-family: Noto Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 13px; /* 108.333% */
`;

const CustomInput = styled(Input)`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  background: var(--scale-light-scale-white, #fff);
  .ant-input-show-count-suffix {
    position: relative;
    left: 8px;
    top: 8px;
    color: var(--scale-gray-scale-low-emp, #b9b9bf);
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
`;

export const SelectWrapper = styled.div`
  .ant-select {
    display: flex;
    align-items: center;
    align-self: stretch;
    width: 100% !important;
    line-height: 14px !important;
    height: 100%;
    background: var(--scale-light-scale-white, #fff);
    height: max-content;
    .ant-select-selector {
      border-radius: 4px;
      padding: 12px 16px !important;
    }
  }
`;

interface PropsType {
  open: boolean;
  onClose: () => void;
  type?: 'add' | 'update';
  category?: any;
  refreshData: () => void;
}

export default function ModifyCategoryModal({
  open = true,
  onClose,
  type = 'add',
  category,
  refreshData,
}: PropsType) {
  const [form] = useForm();
  const [errorMessage, setErrorMessage] = useState('');
  const [name, setName] = useState('');
  const [categoryType, setCategoryType] = useState(CategoryType.imageToImage);
  const [subscriptionType, setSubcriptionType] = useState<string>(SubscriptionType.free);
  const [property, setProperty] = useState('');
  const [description, setDescription] = useState('');
  const inputRef = useRef<any>(null);
  const location = useLocation();
  let { id, projectId } = useParams();
  const [searchParams] = useSearchParams();
  const segmentId = searchParams.get('segmentId');

  const mutationCreateCategory = useMutation((params: any) => createCategory(params), {
    onSuccess: (res) => {
      let data = res?.data;
      if (data) {
        onClose();
        refreshData();
        ToastSuccess('Add category successfully');
      }
    },
    onError: (error: any) => {
      if (error?.response?.data?.error?.errorCode === 8) {
        setErrorMessage(error?.response?.data?.error?.devMessage);
      } else {
        ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
      }
    },
  });

  const mutationUpdateCategory = useMutation(
    (params: any) => updateCategory(category?._id, params),
    {
      onSuccess: (res) => {
        let data = res?.data;
        if (data) {
          onClose();
          refreshData();
          ToastSuccess('Category updated');
        }
      },
      onError: (error: any) => {
        if (error?.response?.data?.error?.errorCode === 8) {
          setErrorMessage(error?.response?.data?.error?.devMessage);
        } else {
          ToastError(error?.response?.data?.error?.devMessage || 'Server Error!');
        }
      },
    }
  );

  const handleAdd = (thumbnailApp: any) => {
    const payload: any = {
      name: name.trim(),
      project: id || projectId,
      type: categoryType,
      subscriptionType: subscriptionType,
      property,
      thumbnailApp,
      description,
    };
    if (segmentId) {
      payload['segment'] = segmentId;
    }
    mutationCreateCategory.mutate(payload);
  };

  const handleUpdate = (thumbnailApp: any) => {
    mutationUpdateCategory.mutate({ name: name.trim(), subscriptionType, property, thumbnailApp, description });
  };

  useEffect(() => {
    if (category?.name) {
      setName(`${category?.name}`);
    }
  }, [category?.name]);

  useEffect(() => {
    if (category?.subscriptionType) {
      setSubcriptionType(`${category?.subscriptionType}`);
    }
  }, [category?.subscriptionType]);

  useEffect(() => {
    if (open) {
      setErrorMessage('');
      setName(`${category?.name || ''}`);
      setCategoryType(category?.type || CategoryType.imageToImage);
      setProperty(category?.property || '');
      setDescription(category?.description || '');
    }
  }, [open, JSON.stringify(category)]);

  const handleOk = async () => {
    if (!name.trim()) {
      setErrorMessage('The category cannot be empty');
      return;
    }
    if (errorMessage) {
      return;
    }
    const values = form.getFieldValue('thumbnailApp');
    const thumbnailApp = [];
    if (values?.length) {
      for (const item of values) {
        if (!item?.thumbnailUrl?.name) {
          thumbnailApp.push(item);
        } else {
          const presign = await fetchPresignUrl(convertFileUpload(item?.thumbnailUrl?.name));
          const upload = await uploadImageApi(presign.thumbnailPresign, item?.thumbnailUrl);
          if (upload?.status !== 200) break;
          thumbnailApp.push({
            thumbnailUrl: presign.filepath,
            thumbnailSize: item?.thumbnailSize,
          });
        }
      }
    }
    if (thumbnailApp?.length < values.thumbnailApp?.length) {
      ToastError('Presign Link Error, Try Again!');
      return;
    }
    if (category) handleUpdate(thumbnailApp);
    else handleAdd(thumbnailApp);
  }

  return (
    <ModalWrapper
      open={open}
      centered
      title={type === 'add' ? 'Add Category' : 'Edit Category'}
      width={1024}
      okText={type === 'add' ? 'Add Category' : 'Update'}
      onCancel={onClose}
      onOk={handleOk}>
      <BodyWrapper>
        <Form
          requiredMark={true}
          colon={false}
          form={form}
          name="createStyle"
          layout="horizontal"
          initialValues={{
            thumbnailApp: category?.thumbnailApp || [],
          }}
          autoComplete="off">
          <FieldWrapper>
            <Label>
              Category Name <span>*</span>
            </Label>
            <CustomInput
              showCount
              maxLength={categoryNameMaxLength}
              ref={inputRef}
              value={name}
              onChange={(e) => {
                const value = e.target?.value || '';
                setName(value);
                if (value.trim()) {
                  setErrorMessage('');
                } else {
                  setErrorMessage('The category cannot be empty');
                }
              }}
              placeholder="category"
              status={errorMessage ? 'error' : ''}
            />
            {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
          </FieldWrapper>
          <FieldWrapper>
            <Label>Type</Label>
            <SelectWrapper>
              <Select
                // suffixIcon={<ArrowDownIcon />}
                size="small"
                // defaultValue={CategoryType.imageToImage}
                value={categoryType}
                disabled={type !== 'add'}
                onChange={(value) => {
                  setCategoryType(value);
                }}>
                {categoryTypeOptions?.map((option) => (
                  <Select.Option value={option.value} label={option.value}>
                    <div>{option.value}</div>
                  </Select.Option>
                ))}
              </Select>
            </SelectWrapper>
          </FieldWrapper>
          <FieldWrapper>
            <Label>Description</Label>
            <TextArea
              key={'description'}
              value={description}
              rows={5}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
              // label="Description"
              name={'description'}
              placeholder={'Description'}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Label>Subscription Type</Label>
            <SelectWrapper>
              <Select
                size="small"
                value={subscriptionType}
                disabled={false}
                onChange={(value) => {
                  setSubcriptionType(value);
                }}
                options={SubOptions}
              />
            </SelectWrapper>
          </FieldWrapper>

          <FieldWrapper style={{marginBottom: 20}}>
            <Label>Property</Label>
            <SelectWrapper>
              <Select
                // suffixIcon={<ArrowDownIcon />}
                size="small"
                // defaultValue={CategoryType.imageToImage}
                allowClear
                value={property}
                onChange={(value) => {
                  setProperty(value);
                }}>
                {propertyOption?.map((option: any) => (
                  <Select.Option value={option.value} label={option.value}>
                    <div>{option.label}</div>
                  </Select.Option>
                ))}
              </Select>
            </SelectWrapper>
          </FieldWrapper>
          <ThumbnailApp form={form} />
        </Form>
      </BodyWrapper>
    </ModalWrapper>
  );
}
