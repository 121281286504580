import { Avatar, Input, Space, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import IconDeleteStyle from '../../assets/images/icon-delete-style.svg';
import IconEditStyle from '../../assets/images/icon-edit-style.svg';
import IconSearch from '../../assets/images/icon-search.svg';
import ArrangeStyleModal from '../../components/ArrangeStyleModal';
import CustomButton from '../../components/Button/CustomButton';
import PlusIcon from '../../components/Icons/PlusIcon';
import ModalConfirmDelete from '../../components/ModalConfirmDelete';
import ModalCustomStyleProject from '../../components/ModalCustomStyleProject';
import Pagination from '../../components/Pagination';
import TableContent from '../../components/TableContent';
import { ToastError, ToastSuccess } from '../../components/toast-message/ToastMessage';
import { toastMessage } from '../../config/constants';
import { RootState } from '../../redux/store';
import { baseModels, FieldConfigStyle, KEY_CONFIG, StyleType, TABS } from '../../constants';
import { getDetailCategory } from '../../services/detailCategoryService';
import {
  deleteStyle,
  getAllStyle,
  getListStyleOfCategory,
  getListStyleOfCategoryAvatar,
} from '../../services/listStyleService';
import IcBackColor from './../../assets/icons/ic_back_color.svg';
import AddStyleModal from './AddStyleModal';
import { BackButton, DetailCategoryWrapper, HeaderTitle } from './style';
import { checkIsImage } from '../../utils/helper';
import { deleteStyleInCategory } from '../../services/categoryService';
import { columnAvatar } from './constants';
import ArrangeStyleAvatarModal from '../../components/ArrangeStyleAvatarModal';
import ModalAvatarStyle from '../../components/ModalAvatarStyle';
const DetailCategoryAvatar = () => {
  const segment = useSelector((state: RootState) => state.style.styleInfo.segment);
  const listImageSize = useSelector((state: RootState) => state.style.styleInfo.listImageSize);

  const [searchParams, setSearchParams] = useSearchParams();
  const { categoryId, projectId } = useParams();
  // const projectName = sessionStorage.getItem('projectName');
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [styleEdit, setStyleEdit] = useState<any>();
  const [styleIds, setStyleIds] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [categoryName, setCategoryName] = useState('');
  const [styles, setStyles] = useState<any>({
    limit: 15,
    totalItems: 9,
    items: [],
  });
  const [allStyles, setAllStyles] = useState<any>([]);
  const [params, setParams] = useState<any>({
    limit: 15,
    page: 1,
    // isStyleOfCategory: true,
  });
  const [nameSearch, setNameSearch] = useState('');
  const [openEditPriority, setOpenEditPriority] = useState(false);
  let [debounce, setDebounce] = useState<any>();
  // const [openAddStyle, setOpenAddStyle] = useState(false);
  const [categoryDetail, setCategoryDetail] = useState<any>();
  const [openCustom, setOpenCustom] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [idStyleDelete, setIdStyleDelete] = useState('');

  const mutationGetListStyle = useMutation(
    () => {
      return getListStyleOfCategoryAvatar(categoryId, params);
    },
    {
      onSuccess: (data) => {
        let order: any = {};
        // styleIds.forEach((item: any, i: number) => {
        //   order[item] = i;
        // });
        // const styleMaps = data?.items?.sort((a: any, b: any) => {
        //   return order[a._id] - order[b._id];
        // });

        setStyles({
          ...styles,
          ...data,
          // items: styleMaps,
        });

        setIsLoading(false);
      },
    }
  );

  const mutationGetAllStyle = useMutation(
    () =>
      getListStyleOfCategoryAvatar(categoryId, {
        page: 1, limit: 1000,
      }),
    {
      onSuccess: (data) => {
        let order: any = {};
        // styleIds.forEach((item: any, i: number) => {
        //   order[item] = i;
        // });
        // const styleMaps = data?.items?.sort((a: any, b: any) => {
        //   return order[a._id] - order[b._id];
        // });
        setAllStyles(data?.items || []);
        setIsLoading(false);
      },
    }
  );
  const muatationDeleteStyleInCategory = useMutation(
    ({ categoryId, payload }: any) => deleteStyleInCategory(categoryId, payload),
    {
      onSuccess: (data) => {
        handleCancelDelete();
        queryClient.invalidateQueries({
          queryKey: ['get-detail-category'],
        });
        if (categoryId) {
          reloadAllStyle()
          mutationGetCategoryDetail.mutate(categoryId);
        }
        ToastSuccess('Delete successfully');
      },
      onError: () => {
        ToastError(toastMessage.error);
      },
    }
  );
  const mutationGetCategoryDetail = useMutation(
    (categoryId: string) => getDetailCategory(categoryId),
    {
      onSuccess: (data) => {
        setCategoryDetail(data);
        setCategoryName(data?.name);
      },
      onError: () => {
        //  ToastError(toastMessage.error);
      },
    }
  );

  useEffect(() => {
    const param: any = {
      page: 1,
      limit: 15,
      search: '',
    };
    const page = searchParams.get('page');
    const limit = searchParams.get('limit');
    const search = searchParams.get('search');
    if (page) {
      param.page = Number(page);
    }
    if (limit) {
      param.limit = Number(limit);
    }
    if (search) {
      param.search = search;
      setNameSearch(search);
    }
    const updatedParams = { ...param };
    if (!search) {
      updatedParams.page = 1;
      updatedParams.limit = 15;
      setNameSearch('');
    }
    setParams(updatedParams);
    setSearchParams(updatedParams);

  }, []);
  useEffect(() => {
    if (categoryId) {
      mutationGetCategoryDetail.mutate(categoryId);
    }
  }, [categoryId]);

  useEffect(() => {
    mutationGetListStyle.mutate();
  }, [JSON.stringify(params)]);

  useEffect(() => {
    mutationGetAllStyle.mutate()
  }, []);

  const handleOpenEditPriority = () => {
    setOpenEditPriority(true);
  };
  const handleOpenEdit = (style: any) => {
    setStyleEdit(style);
    setOpenCustom(true);
  };
  const handleOpenDelete = (id: string) => {
    setOpenDelete(true);
    setIdStyleDelete(id);
  };
  const handleCancelDelete = () => {
    setOpenDelete(false);
    setIdStyleDelete('');
  };
  const handleDelete = () => {
    setOpenDelete(false);
    const payload = {
      styleIds: [idStyleDelete],
    };
    muatationDeleteStyleInCategory.mutate({ categoryId, payload });

    if (styles?.totalItems - 1 === (params?.page - 1) * params?.limit && params?.page > 1) {
      setParams({ ...params, limit: styles.limit, page: styles.page - 1 });
    } else {
      setParams({ ...params, limit: styles.limit, page: styles.page });
    }
  };

  const reloadAllStyle = () => {
    mutationGetListStyle.mutate();
    mutationGetAllStyle.mutate();
  };

  const handleChangeSearchName = (e: any) => {
    clearTimeout(debounce);
    setDebounce(
      setTimeout(() => {
        setParams({ ...params, search: e.target.value, page: 1 });
      }, 500)
    );
    setNameSearch(e.target.value);
  };

  const columns = columnAvatar(params, handleOpenEdit, handleOpenDelete);

  return (
    <DetailCategoryWrapper>
      <div className="content">
        <HeaderTitle>
          <div className="title">{categoryName}</div>
          <Space />
          <BackButton
            onClick={() =>
              navigate(`/projects/${projectId}`, {
                state: {
                  // segment: params.segmentId,
                  tab: TABS.TAB2,
                  type: params.type,
                },
              })
            }>
            <img src={IcBackColor} alt="back-icon" />
            Back to project
          </BackButton>
        </HeaderTitle>
        <div className="list-style">
          <div className="box-search">
            <Input
              allowClear
              value={nameSearch}
              prefix={<img src={IconSearch} alt="" />}
              className="input-search"
              placeholder="Search style name on app"
              onChange={handleChangeSearchName}
            />
            <div className="group-btn">
              <CustomButton
                content=" Edit priority"
                type="outlined"
                onClick={handleOpenEditPriority}
              />
            </div>
          </div>
          <TableContent
            loading={isLoading}
            columns={columns}
            dataSource={styles?.items}
            height="55vh"
          />
        </div>
        <Pagination
          limit={styles?.limit || 0}
          total={styles?.totalItems || 0}
          params={params}
          setParams={setParams}
        />
      </div>
      {openCustom && (
        <ModalAvatarStyle
          open={openCustom}
          setOpen={setOpenCustom}
          params={params}
          setParams={setParams}
          setNameSearch={setNameSearch}
          styleEdit={styleEdit}
          setStyleEdit={setStyleEdit}
          segmentSelect={segment}
          listImageSize={listImageSize}
          reloadAllStyle={reloadAllStyle}
        />
      )}
      {openEditPriority && (
        <ArrangeStyleAvatarModal
          open={openEditPriority}
          setOpen={setOpenEditPriority}
          listStyle={allStyles}
          categoryId={categoryId}
          params={params}
          setParams={setParams}
          refresh={reloadAllStyle}
        />
      )}
      {openDelete && (
        <ModalConfirmDelete
          open={openDelete}
          handleCancelDelete={handleCancelDelete}
          handleDelete={handleDelete}
          title={'Are you sure you want to move this style from the category'}
          tittleDelete={'Remove'}
        />
      )}
    </DetailCategoryWrapper>
  );
};

export default DetailCategoryAvatar;
