import { formatDateToYYYYMMDDHHMM } from '../../../../utils/helper';
import { SUSCRIPTION_TYPE } from '../../../../config/constants';
import IconEditStyle from '../../../../assets/images/icon-edit-style.svg';
import IconDeleteStyle from '../../../../assets/images/icon-delete-style.svg';

export enum CategoryType {
  'imageToImage' = 'imageToImage',
  'textToImage' = 'textToImage',
  'avatar' = 'avatar',
  'aiFashion' = 'aiFashion',
  'inspirations' = 'inspirations',
  'realistic' = 'realistic',
  'notiReminder' = 'notiReminder',
}
export enum SubscriptionType {
  'free' = 'free',
  'ad' = 'ad',
  'premium' = 'premium',
  'adOrPremium' = 'adOrPremium',
}

export enum CategoryProperty {
  'model' = 'model',
  'foryou' = 'foryou',
  'male' = 'male',
  'female' = 'female',
  'banner' = 'banner',
}

export const propertyOption = [
  { key: 'Default', value: '', label: 'default' },
  { key: CategoryProperty.model, value: CategoryProperty.model, label: CategoryProperty.model },
  { key: CategoryProperty.foryou, value: CategoryProperty.foryou, label: CategoryProperty.foryou },
  { key: CategoryProperty.male, value: CategoryProperty.male, label: CategoryProperty.male },
  { key: CategoryProperty.female, value: CategoryProperty.female, label: CategoryProperty.female },
  { key: CategoryProperty.banner, value: CategoryProperty.banner, label: CategoryProperty.banner },
];

export const categoryNameMaxLength = 150;
export const DEFAULT_CATEGORY_TYPE = CategoryType.imageToImage;
export const categoryTypeOptions = [
  {
    label: 'imageToImage',
    value: CategoryType.imageToImage,
  },
  {
    label: 'textToImage',
    value: CategoryType.textToImage,
  },
  {
    label: 'avatar',
    value: CategoryType.avatar,
  },
  {
    label: 'aiFashion',
    value: CategoryType.aiFashion,
  },
  {
    label: 'inspirations',
    value: CategoryType.inspirations,
  },
  {
    label: 'realistic',
    value: CategoryType.realistic,
  },
  {
    label: 'notiReminder',
    value: CategoryType.notiReminder,
  },
];
export const SubOptions = [
  {
    label: 'Free',
    value: SubscriptionType.free,
  },
  {
    label: 'Ad',
    value: SubscriptionType.ad,
  },
  {
    label: 'Premium',
    value: SubscriptionType.premium,
  },
  {
    label: 'Ad or Premium',
    value: SubscriptionType.adOrPremium,
  },
];

export const columnsCategory = (params: any, navigateCategoryStyle: any, handleEdit: any, handleOpenDelete: any) => {
  return [
    {
      title: 'NO',
      dataIndex: 'no',
      key: 'no',
      render: (_: any, record: any, index: number) => params.limit * (params.page - 1) + index + 1,
      width: 70,
    },
    {
      title: 'CATEGORY NAME',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      render: (_: any, record: any, index: number) => {
        return (
          <span
            className="category-name"
            onClick={() => navigateCategoryStyle(record)}>
            {record.name}
          </span>
        );
      },
    },
    {
      title: 'DESCRIPTION',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      // render: (_: any, record: any, index: number) => {
      //   return (
      //     <span
      //       className="category-name"
      //       onClick={() => navigate(`/projects/${projectId}/${record._id}`)}>
      //       {record.name}
      //     </span>
      //   );
      // },
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'createdAt-column',
      width: 120,
      render: (value: string) => {
        const formattedTime = formatDateToYYYYMMDDHHMM(value);
        return <div>{formattedTime || ''}</div>;
      },
    },
    {
      title: 'SUBCRIPTION TYPE',
      dataIndex: 'subscriptionType',
      key: 'subscriptionType',
      width: 160,
      render: (value: string) => {
        return value ? SUSCRIPTION_TYPE[value] : '';
        // if (value !== 'adOrPremium') return <div>{value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}</div>
        // else return <div>Ad or Premium</div>
      },
    },
    {
      title: 'PROPERTY',
      dataIndex: 'property',
      key: 'property',
      width: 160,
      render: (value: string) => {
        return value || 'default';
      },
    },
    {
      title: 'COUNT',
      dataIndex: 'count',
      key: 'count',
      width: 180,
    },
    {
      title: 'ACTION',
      dataIndex: '_id',
      width: 100,
      className: 'action-column',
      fixed: 'right',
      render: (id: string, record: any) => (
        <div className="list-action">
          <img
            src={IconEditStyle}
            alt=""
            onClick={() => handleEdit(record)}
          />
          <img src={IconDeleteStyle} alt="" onClick={() => handleOpenDelete(record)} />
        </div>
      ),
    },
  ]
};
