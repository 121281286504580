import { Avatar, Button } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

export const RES_STATUS = {
  SUCCESS: 200,
};

export const MESSAGE = {
  ERROR: 'Sever Error',
};

export const COLUMN_STYLE = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Negative prompt',
    dataIndex: 'config',
    key: 'negativePrompt',
    render: (value: any) => {
      return <Paragraph>{value?.negativePrompt || ''}</Paragraph>;
    },
  },
  {
    title: 'Positive prompt',
    dataIndex: 'config',
    key: 'positivePrompt',
    render: (value: any) => {
      return <Paragraph>{value?.positivePrompt || ''}</Paragraph>;
    },
  },
  {
    title: 'Config',
    dataIndex: 'config',
    key: 'config',
    render: (value: any) => {
      return <Paragraph>{JSON.stringify(value, null, 2)}</Paragraph>;
    },
  },
  {
    title: 'Key',
    dataIndex: 'key',
    key: 'key',
    render: (value: string) => {
      return <Avatar shape="square" size={128} src={value}></Avatar>;
    },
  },
  {
    title: 'Created At',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
];

export enum Algorithym {
  DIFF_V1 = 'stable-diffusion-v1',
  DIFF_V1_5 = 'stable-diffusion-v1-5',
  DIFF_512_V2_0 = 'stable-diffusion-512-v2-0',
  DIFF_768_V2_0 = 'stable-diffusion-768-v2-0',
  DIFF_512_V2_1 = 'stable-diffusion-512-v2-1',
  DIFF_768_V2_1 = 'stable-diffusion-768-v2-1',
  INPAINTING_V1_0 = 'stable-inpainting-v1-0',
  INPAINTING_512_V2_0 = 'stable-inpainting-512-v2-0',
}

export const AlgorithmList = [
  {
    label: 'stable-diffusion-v1',
    value: 'stable-diffusion-v1',
  },
  {
    label: 'stable-diffusion-v1-5',
    value: 'stable-diffusion-v1-5',
  },
  {
    label: 'stable-diffusion-512-v2-0',
    value: 'stable-diffusion-512-v2-0',
  },
  {
    label: 'stable-diffusion-768-v2-0',
    value: 'stable-diffusion-768-v2-0',
  },
  {
    label: 'stable-diffusion-512-v2-1',
    value: 'stable-diffusion-512-v2-1',
  },
  {
    label: 'stable-diffusion-768-v2-1',
    value: 'stable-diffusion-768-v2-1',
  },
  {
    label: 'stable-inpainting-v1-0',
    value: 'stable-inpainting-v1-0',
  },
  {
    label: 'stable-inpainting-512-v2-0',
    value: 'stable-inpainting-512-v2-0',
  },
];

export const STATUS_VERIFY = {
  ACTIVE: 'active',
  WAITING: 'waiting',
};

export const ROLES = {
  USER: 'USER',
  ADMIN: 'ADMIN',
};

export const ROLE_OPTION = [
  {
    label: 'User',
    value: ROLES.USER,
  },
  {
    label: 'Admin',
    value: ROLES.ADMIN,
  },
];

export const STYLE_MODE = {
  DEFAULT: 'default',
  CUSTOM: 'custom',
};
export const STYLE_MODE_FILTER_OPTION = [
  {
    label: 'Default',
    value: STYLE_MODE.DEFAULT,
  },
  {
    label: 'Custom',
    value: STYLE_MODE.CUSTOM,
  },
];

export const PROJECT_DEFAULT = 'Inhouse Default Configs';

export enum SubscriptionType {
  FREE = 'free',
  AD = 'ad',
  PREMIUM = 'premium',
  AD_PREMIUM = 'ad_premium',
}

export const SubScriptOption = [
  { label: 'Free', value: SubscriptionType.FREE },
  { label: 'Ad', value: SubscriptionType.AD },
  { label: 'Premium', value: SubscriptionType.PREMIUM },
  { label: 'Ad or Premium', value: SubscriptionType.AD_PREMIUM },
];

export const SegmentDefault = [{ label: 'Default segment', value: '' }];

export const SortOption = [
  { label: 'Sort by priority', value: 'priority' },
  { label: 'Sort by date', value: 'createdAt' },
];

export const TABS = {
  TAB1: 'tab1',
  TAB2: 'tab2',
  TAB3: 'tab3',
};

export enum StyleType {
  ART = 'art',
  AI_FASHION = 'ai_fashion',
  AVATAR = 'avatar',
  SUPERART = 'superart',
}

export const styleTypes = [
  {
    value: StyleType.ART,
    label: 'Art',
    fields: [
      'positivePrompt',
      'negativePrompt',
      'alpha',
      'strength',
      'guidanceScale',
      'numInferenceSteps',
    ],
  },
  {
    value: StyleType.AI_FASHION,
    label: 'AI Fashion',
    fields: [
      'positivePrompt',
      'negativePrompt',
      'fixOpenpose',
      'alpha',
      'strength',
      'guidanceScale',
      'numInferenceSteps',
    ],
  },
  { value: StyleType.AVATAR, label: 'Avatar', fields: ['gender'] },
  {
    value: StyleType.SUPERART,
    label: 'Super Art',
    fields: [
      'positivePrompt',
      'negativePrompt',
      'alpha',
      'strength',
      'guidanceScale',
      'numInferenceSteps',
    ],
  },
];

export enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const baseModels = [
  {
    label: 'Origin',
    value: 'origin',
  },
  {
    label: 'LCM',
    value: 'lcm',
  },
];

export const genderOptions = [
  { label: 'Male', value: Gender.MALE },
  { label: 'Female', value: Gender.FEMALE },
];

export const AI_FAMILY_DEFAULT = 'sd1.5';

export const BASE_MODEL_DEFAULT = 'origin';

export const FieldConfigStyle: any = {
  alpha: 'Alpha',
  guidanceScale: 'Guidance Scale',
  mode: 'Mode',
  negativePrompt: 'Negative Prompt',
  numInferenceSteps: 'NumInference Steps',
  positivePrompt: 'Positive Prompt',
  strength: 'Strength',
  style: 'Style',
  gender: 'Gender',
  fixOpenpose: 'Fix Openpose',
  imageSize: 'Image Size',
  baseModel: 'Base Model',
};

export const KEY_CONFIG: any = {
  art: [
    'alpha',
    'guidanceScale',
    'negativePrompt',
    'numInferenceSteps',
    'positivePrompt',
    'strength',
    'mode',
    'style',
    'imageSize',
    'baseModel',
  ],
  ai_fashion: [
    'positivePrompt',
    'negativePrompt',
    'alpha',
    'strength',
    'guidanceScale',
    'numInferenceSteps',
    'fixOpenpose',
    'mode',
    'style',
    'imageSize',
    'baseModel',
  ],
  avatar: ['gender', 'mode', 'style', 'imageSize'],
  superart: [
    'alpha',
    'guidanceScale',
    'negativePrompt',
    'numInferenceSteps',
    'positivePrompt',
    'strength',
    'mode',
    'style',
    'imageSize',
    'baseModel',
  ],
};

export const KEY_NUMBER = ['alpha', 'strength', 'guidanceScale', 'numInferenceSteps', 'mode'];
export const KEY_BOOLEAN = ['fixOpenpose'];
export const DEFAULT_IMAGE_SIZE = '960';
export const StyleConfigMode: any = {
  art: 0,
  ai_fashion: 1,
  avatar: null,
  superart: 2,
};

export const BaseModelConfig: any = {
  origin: 0,
  lcm: 1,
};

export const AI_ArtiMind_IOS = '65b32472a0eb9b3cc616ab53';
